import React, { useEffect, useState } from "react";
import "./TemplateSelector.scss";
import clsx from "clsx";
import { GetLoginedUser, UserLogined, UserMicrosoftLogined, deleteAllCookies } from "../utils";
import { Card, List } from "antd";
import Meta from "antd/es/card/Meta";
import { ToolButton } from "./ToolButton";
import { IconCloseWin, SearchIcon, TemplateIcon, AIBotIcon, AIBotCloseIcon } from "./icons";
import { AppState } from "../types";
import { Modal } from "./Modal";
import { loadFromBlob } from "../data";
import { createFile, getMimeType } from "../data/blob";
import { inflate } from "pako";
import { NavLink, Route } from "react-router-dom";
import { checkLangKey, t } from "../i18n";
import { split } from "lodash";
import { delDataFromStorage } from "../imago-app/data/localStorage";
import { UserPlanChange } from "./UserPlanChange";
import { ChangePassword } from "./ChangePassword";
import { LocalData } from "../imago-app/data/LocalData";

export const TemplateSelector: React.FC<{
  appState: AppState;
  addFileToCanvas: (file: File) => Promise<void>;
}> = ({ appState, addFileToCanvas }) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [keywords, setKeyWords] = useState<string>("");

  const handleSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode == 13) {
      setKeyWords(event.target.value);
    }
  };

  const handelCardClick = async (tpl: string) => {
    setOpenModal(false);
    try {
      const request = await fetch(`/api/v1/download/templates?path=${tpl}`, {
        headers: {
          Authorization: `Bearer ${appState.userInfo?.authorization}`,
        },
      });
      const data = await request.blob();
      await addFileToCanvas(createFile(data, getMimeType(data), "a"));
    } catch (error: any) { }
  };

  return (
    <>
      <ToolButton
        className={""}
        key=""
        icon={TemplateIcon}
        type="icon"
        name="ViewBackgroundSelect"
        id="ViewBackgroundSelect"
        //checked={appState.viewBackgroupStyle === selected.value}
        aria-label={"ViewBackgroundSelect"}
        //onChange={() => setActive(!isActive)}
        title={"ViewBackgroundSelect"}
        onClick={() => {
          setOpenModal(true);
        }}
      />
      {openModal && (
        <Modal
          labelledBy="dialog-title"
          maxWidth={960}
          onCloseRequest={() => {
            setOpenModal(false);
          }}
          closeOnClickOutside={true}
        >
          <div className="template-modal">
            <div></div>
            <div>
              <a
                className="modal-close"
                href={undefined}
                onClick={() => {
                  setOpenModal(false);
                }}
              >
                {IconCloseWin}
              </a>
              <div style={{ display: "none" }} className="search">
                {SearchIcon}
                <input
                  placeholder={t("home_welcome.search_template_by_name")}
                  onKeyDown={handleSearch}
                ></input>
              </div>
            </div>
            <Templates
              keywords={keywords}
              listColumn={3}
              handleCardClick={(item) => handelCardClick(item.source)}
              listLimit={6}
            ></Templates>
          </div>
        </Modal>
      )}
    </>
  );
};

export const getCatName = (item: any): string => {
  if (!item || !item.nameKey) {
    console.error("Invalid item passed to getCatName:", item);
    return "";
  }
  let key = item.nameKey.replace("ctg_", "");
  if (!item.nameKey.endsWith("_")) {
    key = key.concat("_");
  }
  const cat_key = `data_record.template.${key}`;
  if (checkLangKey(cat_key)) {
    const v = t(cat_key);
    if (v != "") {
      return v;
    }
    return item.name;
  }
  return item.name;
};


export const getTemplateName = (item: any): string => {
  const arr = item.nameKey.split("_");
  if (arr.length == 2) {
    const key = arr[0].concat("_");
    const temp_key = `data_record.${key}`;
    if (checkLangKey(temp_key)) {
      const v = t(temp_key);
      if (v != "") {
        return `${v} ${arr[1]}`;
      }
      return item.name;
    }
    return item.name;
  }
  return item.name;
};

export const Templates: React.FC<{
  keywords: string;
  listColumn?: number;
  listLimit?: number;
  handleCardClick: (item: any) => void;
  showMenu?: boolean;
  showCategory?: boolean;
  showSuggestedTemplates?: boolean;
  showAiBot?: boolean;
}> = ({
  keywords,
  listColumn = 4,
  listLimit = 12,
  handleCardClick,
  showMenu = true,
  showCategory = true,
  showSuggestedTemplates = false,
  showAiBot = true
}) => {
    const [title, setTitle] = useState(t("labels.all_templates"));
    const [templatesTotal, setTemplateTotal] = useState(0);
    const [categoriesTotal, setCategoriesTotal] = useState(0);
    const [templates, setTemplates] = useState<any[]>();
    const [suggestedTemplates, setSuggestedTemplates] = useState<any[]>();
    const [page, setPage] = useState(1);
    const [categoryId, setCategoryId] = useState("0");
    const [categories, setCategories] = useState<any[]>();
    const host = "";
    const user = GetLoginedUser();
    const isLogined = UserLogined();
    const isMicrosoftLogined = UserMicrosoftLogined();
    const [showModal, setShowModal] = useState(false);

    const [showPop, setShowPop] = useState<boolean>(false);
    const [showChangePassword, setShowChangePassword] = useState<boolean>(false);
    const [showUserPlanChange, setShowUserPlanChange] = useState<boolean>(false);

    const onSignout = () => {
      setShowPop(false);
      logout();
    };
    const logout = async () => {
      // document.cookie = `${COOKIES.AUTH}=;max-age=0;Expires=0`;
      // document.cookie = `${COOKIES.LICENCE}=;max-age=0;Expires=0`;
      // document.cookie = `auth=;max-age=0;Expires=0`;
      deleteAllCookies();
      delDataFromStorage("google_access_token");
      await LocalData.clearPages();
      await LocalData.dataStateStorage.clear();

      fetch("/api/v1/logout", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${user?.authorization}`,
        },
      }).then(() => {
        localStorage.removeItem("logout");
        location.reload();
        location.href = "/dashboard";
      });
    };

    const handleMenuItemClick = ({
      title,
      activeMenuItem,
    }: {
      title: string;
      activeMenuItem: string;
    }) => {
      setTitle(title);
      setCategoryId(activeMenuItem);
      setPage(1);
    };

    useEffect(() => {
      let templates: any;
      (async () => {
        const response = await fetch(
          `${host}/api/v1/categories/${categoryId}/templates?page=${page}&limit=${listLimit}&key=${keywords}`,
          {
            headers: {
              Authorization: `Bearer ${user?.authorization}`,
            },
          },
        ).then(async (response) => {
          templates = await response.json();
          setTemplates(templates.data.templates);
        }).then(() => {
          // let tempTemplates: any[] = [];
          // const targetProbability = 30;
          // let i = 0;

          // // (let i = 0; i < templates.data.templates.length; i++)
          // while (tempTemplates.length < 4) {

          //   // Generate the probability to select this template
          //   let RandomGeneratedNumber = (Math.random() * (100 - 1));
          //   if (RandomGeneratedNumber <= targetProbability &&
          //     !tempTemplates.includes(templates.data.templates[i])) {
          //     // console.log("Selected suggested template : ", templates.data.templates[i])
          //     tempTemplates.push(templates.data.templates[i]);
          //   }

          //   if (i >= templates.data.templates.length) {
          //     // console.log("Didn't reached enough templates, retrying...")
          //     i = 0;
          //   }



          //   i++;
          // }

          // setSuggestedTemplates(tempTemplates);
        });

      })();
    }, [page, categoryId, keywords, listLimit]);

    useEffect(() => {
      (async () => {
        const response = await fetch(
          `${host}/api/v1/stats/categories/${categoryId}/templates`,
          {
            headers: {
              Authorization: `Bearer ${user?.authorization}`,
            },
          },
        );
        setTemplateTotal((await response.json()).data);
      })();
    }, [categoryId, keywords]);

    useEffect(() => {
      fetch(`${host}/api/v1/categories?app=1`, {
        headers: {
          Authorization: `Bearer ${user?.authorization}`,
        },
      }).then(async (response) => {
        const categories = (await response.json()).data;
        setCategories(categories);
        setCategoriesTotal(categories.length);
      });
    }, []);

    if (!isLogined && !isMicrosoftLogined) {
      location.href = "/";
      return null;
    }

    return (
      <>

        {showMenu && (
          <>
            <div className="leftPanel">
              <div className="logo">
                <img
                  src="/home/asset/logo.svg"
                  alt="logo" />
              </div>
              <div className="menu">
                <ul className="upper-menu">

                  <li>
                    <NavLink to="/dashboard">{t("home_welcome.home")}</NavLink>
                  </li>

                  {/* <li>
                    <NavLink to="/recent">{t("home_welcome.recent")}</NavLink>
                  </li> */}

                  <li>
                    <NavLink to="/templates-categories">
                      {t("home_welcome.templates")}
                    </NavLink>
                  </li>
                </ul>

                <ul className="divider"></ul>

                <ul className="lower-menu">
                  {user?.roleEn !== "Guest" && (
                    <li
                      onClick={(e) => {
                        setShowChangePassword(true);
                        setShowPop(false);
                        e.stopPropagation();
                      }}
                    >
                      {t("buttons.changepassword")}
                    </li>
                  )}

                  {/* User Plan */}
                  <li
                    onClick={(e) => {
                      setShowUserPlanChange(true);
                      setShowPop(false);
                      e.stopPropagation();
                    }}
                  >
                    {t("buttons.upgradeLicense")}
                    {/* {t(`pricing.${user?.roleEn.toLowerCase()}`)} */}
                    {user?.isFreeTrial ? ` - ${t("winTabList.free_trial")}` : ""}
                  </li>

                  {/* Sign Out */}
                  <li
                    onClick={onSignout}
                  >
                    {t("buttons.signout")}
                  </li>

                  {/* User Plan Change Modal */}
                  {showUserPlanChange && (
                    <Modal
                      labelledBy="dialog-title"
                      maxWidth={800}
                      onCloseRequest={() => setShowUserPlanChange(false)}
                      closeOnClickOutside={true}
                    >
                      <a
                        className="modal-close"

                        onClick={() => setShowUserPlanChange(false)}
                      >
                        {IconCloseWin}
                      </a>
                      <UserPlanChange onClose={() => setShowUserPlanChange(false)} />
                    </Modal>
                  )}

                  {/* Change Password Modal */}
                  {showChangePassword && (
                    <Modal
                      labelledBy="dialog-title"
                      maxWidth={400}
                      onCloseRequest={() => setShowChangePassword(false)}
                      closeOnClickOutside={true}
                    >
                      <div className="modal-close">
                        <a onClick={() => setShowChangePassword(false)}>
                          {IconCloseWin}
                        </a>
                      </div>
                      <ChangePassword onClose={() => setShowChangePassword(false)} />
                    </Modal>
                  )}

                </ul>
              </div>

            </div>
          </>)}
        {showCategory && (
          <div className="content">
            <h2>{t("home_welcome.categories")}</h2>

            <div className="content-row">
              <div className="category-list">

                {categories?.map((c) => (
                  <li
                    key={c.id}
                    className={clsx({ active: categoryId == c.id })}
                    onClick={() =>
                      handleMenuItemClick({ title: c.nameKey, activeMenuItem: c.id })
                    }
                  >
                    {getCatName(c)}
                  </li>
                ))}
              </div>


              <div className="template-list">
                <List
                  grid={{ xs: 1, sm: 2, md: 3, column: listColumn }}
                  pagination={
                    templatesTotal > listLimit
                      ? {
                        onChange: (page) => {
                          setPage(page);
                        },
                        pageSize: listLimit,
                        total: templatesTotal,
                        current: page,
                      }
                      : false
                  }
                  dataSource={templates}
                  renderItem={(item) => (
                    <Card
                      hoverable
                      key={item.id}
                      style={{ margin: "10px" }}
                      cover={
                        <div className="card-cover">
                          <img
                            alt={item.nameKey}
                            src={`/api/v1/download/template-images?path=${item.snapshortUrl}`}
                          />
                        </div>
                      }
                      onClick={() => handleCardClick(item)}
                    >
                      <Meta title={getTemplateName(item)} />
                    </Card>
                  )}
                />
              </div>


              {showAiBot && (
                <div className="ai-bot"
                  onClick={() => setShowModal((prev) => !prev)}
                >
                  {AIBotIcon}
                </div>
              )}

              {showModal && (
                <div className="modal-overlay">
                  <div className="modal-content">
                    <button
                      className="modal-close"
                      onClick={() => setShowModal(false)}
                    >
                      {AIBotCloseIcon}
                    </button>
                    <h2>{t("home_welcome.collab_bot")}</h2>

                    <iframe
                      src="https://dify.imago.work/chat/gNXdHGchtm9p6naJ"
                      style={{
                        width: "100%",
                        height: "90%",
                        minHeight: "450px",
                        border: "none",
                      }}
                    ></iframe>
                  </div>
                </div>
              )}

            </div>



          </div>
        )}
        {showSuggestedTemplates && (
          <div className="content">
            <h2>Suggested template</h2>
            {/* <h2>{t("home_welcome.categories")}</h2> */}

            <div className="content-row">
              {/* <div className="category-list">

            {categories?.map((c) => (
              <li
                key={c.id}
                className={clsx({ active: categoryId == c.id })}
                onClick={() =>
                  handleMenuItemClick({ title: c.nameKey, activeMenuItem: c.id })
                }
                >
                {getCatName(c)} 
              </li>
            ))}
            </div> */}


              <div className="template-list">
                <List
                  grid={{ xs: 1, sm: 2, md: 3, column: listColumn }}
                  dataSource={suggestedTemplates}
                  renderItem={(item) => (
                    <Card
                      hoverable
                      key={item.id}
                      style={{ margin: "10px" }}
                      cover={
                        <div className="card-cover">
                          <img
                            alt={item.nameKey}
                            src={`/api/v1/download/template-images?path=${item.snapshortUrl}`}
                          />
                        </div>
                      }
                      onClick={() => handleCardClick(item)}
                    >
                      <Meta title={getTemplateName(item)} />
                    </Card>
                  )}
                />
              </div>
            </div>
          </div>
        )}


      </>
    );
  };
