import { StrictMode, lazy } from "react";
import { createRoot } from "react-dom/client";
import ImagoApp from "./imago-app";

import "./imago-app/pwa";
import "./imago-app/sentry";
import Calendar from "./imago-app/calendar";
import {
  clearAllStorageData,
  delDataFromStorage,
  getItemWithExpiration,
  setItemWithExpiration,
} from "./imago-app/data/localStorage";
import { LocalData } from "./imago-app/data/LocalData";
window.__IMAGO_SHA__ = process.env.REACT_APP_GIT_SHA;
const rootElement = document.getElementById("root")!;
const root = createRoot(rootElement);

const pathname = window.location.pathname;

if (pathname.startsWith("/board")) {


  const urlParams = new URLSearchParams(window.location.hash.slice(1));
  // const urlParams = new URLSearchParams(window.location.hash);
  const accessToken = urlParams.get('access_token');
  const expiresIn = urlParams.get('expires_in');

  if (accessToken && expiresIn && getItemWithExpiration("requestGoogleAuth") !== "") {
    document.cookie = `g_token=${accessToken}|${expiresIn};max-age=${expiresIn};path=/; SameSite=None; Secure`;
    setItemWithExpiration("google_access_token", accessToken, Number.parseInt(expiresIn))
    const requestGoogleAuth = getItemWithExpiration("requestGoogleAuth");

    delDataFromStorage("requestGoogleAuth")
    if (requestGoogleAuth === "app-login") {
      window.location.href = window.location.origin + '/google-auth-success?isLogin=1'
    } else if (requestGoogleAuth === "app-google-cloud-server") {
      window.location.href = window.location.origin + '/app-google-cloud-server'
    } else if (requestGoogleAuth === "app-save-to-google-server") {
      window.location.href = window.location.origin + '/app-save-to-google-server'
    } else if (requestGoogleAuth === "app-qrcode-login") {
      window.location.href = window.location.origin + '/app-qrcode-login'
    } else {
      location.href = "/board";
    }

  } else {
    const ImagoApp = lazy(() => import("./imago-app"));
    root.render(
      <StrictMode>
        <ImagoApp />
      </StrictMode>,
    );
  }


} else if (pathname.startsWith("/admin")) {
  const Admin = lazy(() => import("./admin"));
  root.render(
    <StrictMode>
      <Admin />
    </StrictMode>,
  );
} else {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  if (urlParams.get("id") && urlParams.get("authorization")) {
    const data = {
      id: urlParams.get("id"),
      username: urlParams.get("username"),
      roleEn: urlParams.get("roleEn"),
      authorization: urlParams.get("authorization"),
      email: urlParams.get("email"),
    };

    if (urlParams.get("source") === "android") {
      const encodedData = btoa(JSON.stringify(data));
      document.cookie = `authb=${encodedData};max-age=${24 * 60 * 60};path=/`;
    } else {
      if (urlParams.get("for_token") != "1") {
        document.cookie = `auth=${JSON.stringify(data)};max-age=${24 * 60 * 60
          };path=/; SameSite=None; Secure`;
      }

      if (!urlParams.get("clear") || urlParams.get("clear") == "true") {
        LocalData.clearPages();
        LocalData.dataStateStorage.clear();
      }

      const accessToken = urlParams.get("access_token");
      const expiresIn = urlParams.get("expires_in");

      if (accessToken && expiresIn) {
        setItemWithExpiration(
          "google_access_token",
          accessToken,
          Number.parseInt(expiresIn),
        );
        document.cookie = `g_token=${accessToken}|${expiresIn};max-age=${expiresIn};path=/; SameSite=None; Secure`;
      }
      const redirect = urlParams.get("redirect");

      setTimeout(() => {
        if (window.opener) {
          window.opener.location.reload();
          window.close();
        } else {
          if (redirect && redirect.indexOf("/board/") > -1) {
            location.href = redirect;
          }

        }
      }, 1000);
    }
  }

  const Front = lazy(() => import("./front"));
  root.render(
    <StrictMode>
      <Front />
    </StrictMode>,
  );
}
